
















































import { Component, Prop } from "vue-property-decorator";
import Model from "@/components/designer/sidebar/shape/Model.vue";
import { UmbrellaModel } from "@/models/products/umbrella";
import ProductService from "@/services/product_service";
import { mixins } from "vue-class-component";
import { LayoutStore, ShapeStore } from "@/mixins/store";
import { Collection } from "@/models/products/collection";
import { ProductListRes } from "@/models/products/product";
import { APIError, NotFoundError } from "@/services/error_service";
import { CanopyStore } from "@/mixins/store";
import { ConfigCreator } from "@/mixins/configurator";
import { FabricRules } from "@/mixins/business_logic/global/fabric_rules";
import NoStripes from "@/components/designer/reusable/modal/NoStripes.vue";
import { EventBus } from "@/event-bus";
import { StockMixin } from "@/mixins/stock";
import * as option from "@/models/configurator/configurator_options";
import { LayoutRules } from "@/mixins/business_logic/layout/layout_rules";

@Component({
  components: {
    Model,
    NoStripes
  }
})
export default class ShapeSidebar extends mixins(
  ShapeStore,
  LayoutStore,
  CanopyStore,
  ConfigCreator,
  FabricRules,
  StockMixin,
  LayoutRules
) {
  @Prop({ default: false }) customizable!: boolean;
  @Prop() modalId!: string;
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  protected umbrellaData = {} as Collection;
  protected productService = new ProductService();
  protected selected: UmbrellaModel | null = null;
  protected dialog = false;

  async mounted() {
    EventBus.$on("toggleStripesModal", () => this.showStripesWarning());
    const loader = this.$loading.show({
      isFullPage: false,
      container: this.$refs.spinnerContainer
    });
    const apiRes = await this.getUmbrellas();
    this.umbrellaData = this.getCollection(apiRes);
    if (process.env.VUE_APP_ENV === 'production') {
      this.filterUmbrellaModels(this.umbrellaData); // temp section to filter out broken model
    }
    this.readStoreData();
    loader.hide();
  }

  beforeDestroy() {
    EventBus.$off("toggleStripesModal");
  }

  protected filterUmbrellaModels(data : Collection) : void{
    let filteredModels = [];
    for (const model of data.models) {
      if (model.model !== '876FMA-R') {
        filteredModels.push(model);
      }
    }
    this.umbrellaData.models = filteredModels as UmbrellaModel[];
  }

  protected showStripesWarning(): void {
    this.dialog = true;
  }

  protected getCollection(data: Collection[]): Collection {
    const collection = data.filter((umbrellaObject: Collection) => {
      return umbrellaObject.display_name === this.collection.display_name;
    });
    return collection[0];
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected model to the first one in model array
   */
  protected readStoreData(): void {
    if (!this.umbrellaModel) {
      this.$router.push("/");
    } else {
      this.selected = this.umbrellaModel;
    }
  }

  protected async select(model: UmbrellaModel): Promise<void> {
    this.selected = model;
    if (
      model.model.indexOf("-R") > -1 ||
      (model.shape && model.shape[0].toLowerCase() === "rectangle")
    ) {
      // if rectangle canopy
      const stripesFound = await this.checkModelForStripes();
      if (stripesFound) {
        await this.toggleStripeWarning();
        this.selected = this.umbrellaModel; // resetting selection in ui to previous model
      } else {
        this.changeModel(model);
      }
    } else {
      this.changeModel(model);
    }
  }

  protected async changeModel(model: UmbrellaModel): Promise<void> {
    this.addUmbrellaModel(model);
    EventBus.$emit('startModelChange');
    await this.$viewer.ChangeSize(model.model);
  }


  protected async getUmbrellas(): Promise<Collection[]> {
    let umbrella: Collection[] = [];
    try {
      const res: ProductListRes = await this.productService.getUmbrellas();
      umbrella = res.data!;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrella;
  }

  protected getShapeImg(shape: string): string {
    let shapeImage = "";
    if (shape === "Square") {
      shapeImage = require("../../../../assets/images/shape-square.svg");
    } // "require" is needed because of webpack issue with compiling dynamic image urls
    else if (shape === "Rectangle") {
      shapeImage = require("../../../../assets/images/shape-rectangle.svg");
    } else {
      shapeImage = require("../../../../assets/images/shape-octagon.svg");
    }
    return shapeImage;
  }
}
